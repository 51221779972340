/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LocalImages from "../../Utils/localImages";
import EllipsisToolTip from "ellipsis-tooltip-react-chan";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { NotificationsNone } from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Pathname from "../../Utils/pathname";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import Utils from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessProfileState,
  getProfileState,
} from "../../screen/dashboard/settings/businessProfile/reducer";
import LogoutModal from "./LogoutModal";
import { Roles, UserTypes } from "../../Utils/LocalStorageConstants";
import HeaderModal from "./HeaderModal";
import NotificationModal from "./NotificationModal";
import { ReducersModel } from "../../model";
import { getNotificationListData } from "./action";

const { Logo } = LocalImages;

const StyledAppBar = styled(AppBar)(({ theme }) => {
  return {
    background: "#f6f6f6",
    height: "4.62rem",
    boxShadow: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1rem 6rem 1rem 3rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem .8rem 1rem 1rem",
    },
  };
});
const StyledLogo = styled("img")(({ theme }) => ({
  width: "8.25rem",
  height: "2.5rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const NavListBoxStart = styled(StyledBox)(({ theme }) => ({
  justifyContent: "flex-start",
  "& p:first-of-type": {
    marginLeft: "6rem !important",
  },

  columnGap: "2.5rem",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#414042",
    maxWidth: "15rem",
    fontSize: "1.3rem",
    border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      background: "#fff",
      border: "1px solid #dadde9",
      display: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        background: "#fff",
        border: "1px solid #dadde9",
      },
      transform: "translate(25px, 0px) !important",
    },
  },
}));

const CustomRequestToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.05)",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.3rem",
    border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      background: "#fff",
      border: "1px solid #dadde9",
    },
  },
  [theme.breakpoints.up("md")]: {
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        background: "#fff",
        border: "1px solid #dadde9",
      },
      transform: "translate(25px, 0px) !important",
    },
  },
}));

// eslint-disable-next-line react-hooks/rules-of-hooks

const handleLogout = (history: any, setLogoutModalOpen: any) => () => {
  Utils.API.postApiCall(
    Utils.endPoint.logout,
    {},
    (res: any) => {
      console.log(res);
      localStorage.clear();
      history.push("/");
    },
    (err: any) => {
      console.log(err);
    }
  );
  setLogoutModalOpen(false);
};

const SignInAsConpanyList = [
  { Name: "DASHBOARD", pathname: Pathname.DASHBOARD },
  { Name: "REQUESTS", pathname: Pathname.HEADER.REQUESTS },
  { Name: "PROVIDERS", pathname: Pathname.landing },
];

function Header() {
  // const [status, setStatus] = React.useState("client");
  const status = "client";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [headerModalOpen, setHeaderModalOpen] = React.useState(false);
  const [settingsEl, setSettingsEl] = React.useState<null | HTMLElement>(null);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const [notificationModalOpen, setNotificationModalOpen] =
    React.useState(false);
  const LocalStorageRole = localStorage.getItem("roles");
  const LocalStorageUserType = localStorage.getItem("userType");
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleMenu = () => {
    setHeaderModalOpen(true);
  };
  const ProfileState = useSelector(getBusinessProfileState);

  React.useEffect(() => {
    dispatch(getNotificationListData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { notificationListData } = useSelector(
    (state: ReducersModel) => state.notificationListingReducer
  );

  const handleSettingsMenu = (event: any) => {
    setSettingsEl(event.currentTarget);
  };
  const options = {
    effect: "solid",
    place: "left",
  };

  const handleHamburgerClose = () => {
    setAnchorEl(null);
  };

  const handleClose = (el: any) => () => {
    history.push(el?.pathname);
    setAnchorEl(null);
  };
  const handleSettingsClose = () => {
    setSettingsEl(null);
  };

  React.useEffect(() => {
    if (!isSmallScreen) {
      handleSettingsClose();
      handleHamburgerClose();
    }
  }, [isSmallScreen]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "100",
        }}
      >
        <StyledAppBar position="static">
          <IconButton
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                display: "none",
              },
            })}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>

          {LocalStorageUserType === UserTypes.CLIENT ? (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleHamburgerClose}
            >
              {SignInAsConpanyList.map((el: any) => {
                if (el?.Name === "INBOX" && LocalStorageRole === Roles.ADMIN) {
                  return null;
                }
                return (
                  <MenuItem key={el.Name} onClick={handleClose(el)}>
                    {el.Name}
                  </MenuItem>
                );
              })}
            </Menu>
          ) : null}

          <StyledLogo src={`${Logo}`} alt="logo" />

          {LocalStorageUserType === UserTypes.CLIENT ? (
            <NavListBoxStart flex="1">
              {SignInAsConpanyList.map((el) => {
                if (el?.Name === "INBOX" && LocalStorageRole === Roles.ADMIN) {
                  return null;
                }
                if (el.Name === "REQUESTS") {
                  return (
                    <RequestToolTip key={el.Name}>
                      <Button
                        sx={(theme) => ({
                          [theme.breakpoints.down("md")]: {
                            display: "none",
                          },
                          borderRadius: 0,
                          fontSize: "1rem",

                          fontWeight: 500,
                          color:
                            pathname === Utils.Pathname.HEADER.REQUESTS ||
                            pathname === Utils.Pathname.SAVEDCOMPANY
                              ? "#4f4f4f"
                              : "#bdbdbd",
                          "&:hover": {
                            background: "transparent",
                            color: "#4f4f4f",
                          },
                        })}
                        disableRipple
                      >
                        Requests
                        <KeyboardArrowDownIcon />
                      </Button>
                    </RequestToolTip>
                  );
                }
                if (el.Name === "PROVIDERS") {
                  return (
                    <ProviderToolTip key={el.Name}>
                      <Button
                        sx={(theme) => ({
                          [theme.breakpoints.down("md")]: {
                            display: "none",
                          },
                          borderRadius: 0,
                          fontSize: "1rem",

                          fontWeight: 500,
                          color:
                            pathname === Utils.Pathname.HEADER.REQUESTS ||
                            pathname === Utils.Pathname.SAVEDCOMPANY
                              ? "#4f4f4f"
                              : "#bdbdbd",
                          "&:hover": {
                            background: "transparent",
                            color: "#4f4f4f",
                          },
                        })}
                        disableRipple
                      >
                        Providers
                        <KeyboardArrowDownIcon />
                      </Button>
                    </ProviderToolTip>
                  );
                }
                return (
                  <CustomActiveLink
                    label={el.Name}
                    to={el.pathname}
                    key={el.Name}
                  />
                );
              })}
            </NavListBoxStart>
          ) : null}

          <StyledBox>
            {status !== "client" ? (
              <>
                <Typography sx={{ marginRight: "1.5rem" }}>LOGIN</Typography>
                <Button
                  sx={{ color: "#fff" }}
                  color="primary"
                  variant="contained"
                >
                  SIGN UP
                </Button>
              </>
            ) : (
              <>
                <Box
                  onClick={() => {
                    setNotificationModalOpen(true);
                  }}
                  sx={{
                    height: notificationModalOpen == true ? "40px" : "",
                    width: notificationModalOpen == true ? "40px" : "",
                    backgroundColor:
                      notificationModalOpen == true ? "#fff" : "",
                    borderRadius: notificationModalOpen == true ? "50%" : "",
                    margin:
                      notificationModalOpen == true
                        ? "0% -8% 0% 5%"
                        : "2% 0% 0% -1%",
                  }}
                >
                  <img
                    src={
                      notificationListData.length == 0
                        ? LocalImages.Notifications
                        : LocalImages.Notifications_dot
                    }
                    // src={LocalImages.Notifications_dot}
                    alt=""
                    height="18px"
                    width="14px"
                    style={{
                      margin: notificationModalOpen == true ? "27%" : "5%",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <PersonOutlineIcon
                  onClick={handleSettingsMenu}
                  sx={(theme) => ({
                    cursor: "pointer",
                    marginLeft: "2rem",
                    display: "none",
                    [theme.breakpoints.down("md")]: {
                      display: "block",
                    },
                  })}
                />
                <Menu
                  id="menu-appbar"
                  anchorEl={settingsEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(settingsEl)}
                  onClose={handleSettingsClose}
                >
                  <MenuItem onClick={handleSettingsClose}>
                    <Typography color="inherit" sx={{ paddingY: ".7rem" }}>
                      <Link
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                        to={Pathname.Settings.Mydetails}
                      >
                        <SettingsIcon sx={{ marginRight: ".5rem" }} /> Settings
                      </Link>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleSettingsClose}>
                    <Typography
                      onClick={() => {
                        setLogoutModalOpen(true);
                      }}
                      color="inherit"
                      sx={{
                        cursor: "pointer",
                        paddingY: ".7rem",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ExitToAppIcon sx={{ marginRight: ".5rem" }} /> Logout
                    </Typography>
                  </MenuItem>
                </Menu>
                <NavToolTip setLogoutModalOpen={setLogoutModalOpen}>
                  <Button
                    sx={(theme) => ({
                      marginLeft: "1rem",
                      [theme.breakpoints.down("md")]: {
                        display: "none",
                      },
                      borderRadius: 0,
                      color: "#4f4f4f",
                      "&:hover": {
                        background: "transparent",
                        color: "#4f4f4f",
                      },
                    })}
                    disableRipple
                  >
                    <span
                      style={{
                        maxWidth: "120px",
                        paddingTop: "8px",
                      }}
                    >
                      <EllipsisToolTip options={options}>
                        {localStorage.getItem("user_name")}
                      </EllipsisToolTip>
                    </span>

                    <KeyboardArrowDownIcon />
                  </Button>
                </NavToolTip>
              </>
            )}
          </StyledBox>
        </StyledAppBar>
      </Box>
      <LogoutModal
        ModalOpen={logoutModalOpen}
        setModalOpen={setLogoutModalOpen}
        handleLogout={handleLogout(history, setLogoutModalOpen)}
      />
      <HeaderModal
        open={headerModalOpen}
        handleClose={() => {
          setHeaderModalOpen(false);
        }}
        setLogoutOpen={setLogoutModalOpen}
      />
      <NotificationModal
        ModalOpen={notificationModalOpen}
        setModalOpen={setNotificationModalOpen}
        handleLogout={handleLogout(history, setLogoutModalOpen)}
      />
    </>
  );
}

const CustomActiveLink = ({ to, label }: any) => {
  const match = useRouteMatch({
    path: to,
  });

  const history = useHistory();

  return (
    <Typography
      sx={{
        color: match ? "#4f4f4f" : "#bdbdbd",
        fontWeight: "500",
        fontSize: "16px",
        cursor: "pointer",
        "&:hover": {
          color: "#4f4f4f",
        },
      }}
      variant="body2"
      onClick={() => {
        history.push(to);
      }}
    >
      {label}
    </Typography>
  );
};

const NavToolTip = ({ children, setLogoutModalOpen }: any) => {
  const [open, setOpen] = React.useState(false);

  const ProfileState = useSelector(getProfileState);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <CustomToolTip
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disableFocusListener
        placement="bottom-start"
        title={
          <Stack direction="column" sx={{ width: "15rem" }}>
            <Typography
              color="inherit"
              sx={{ padding: "1rem 1rem", borderBottom: "1px solid #E0E0E0" }}
              onClick={() => handleClose()}
            >
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.Settings.Mydetails}
              >
                {ProfileState &&
                ProfileState.profileDetailsData.profilePicture ? (
                  <Avatar
                    src={
                      (ProfileState &&
                        ProfileState.profileDetailsData.profilePicture) ||
                      "String"
                    }
                    sx={{
                      height: "40px",
                      width: "40px",
                      marginRight: "12px",
                    }}
                  />
                ) : (
                  <AccountCircleOutlinedIcon
                    sx={{
                      fontSize: "1.5rem",
                      marginRight: "12px",
                    }}
                  />
                )}
                {ProfileState.profileDetailsData.name}
                {/* <SettingsIcon sx={{ marginRight: ".5rem" }} /> Company Profile */}
              </Link>
            </Typography>
            <Typography
              color="inherit"
              sx={{ paddingY: "1rem" }}
              onClick={() => handleClose()}
            >
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.Settings.Mydetails}
              >
                <img
                  src={LocalImages.User1}
                  alt=""
                  height="20px"
                  width="20px"
                  style={{ margin: "0rem 1rem 0rem 1.5rem" }}
                />
                Account
                {/* <SettingsIcon sx={{ marginRight: ".5rem" }} /> Company Profile */}
              </Link>
            </Typography>
            <Typography
              color="inherit"
              sx={{ paddingY: "1rem" }}
              onClick={() => handleClose()}
            >
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.Settings.BusinessProfile}
              >
                <img
                  src={LocalImages.Frame}
                  alt=""
                  height="20px"
                  width="20px"
                  style={{ margin: "0rem 1rem 0rem 1.5rem" }}
                />
                Company Profile
                {/* <SettingsIcon sx={{ marginRight: ".5rem" }} /> Company Profile */}
              </Link>
            </Typography>
            <Typography
              onClick={() => setLogoutModalOpen(true)}
              color="inherit"
              sx={{
                cursor: "pointer",
                paddingY: "1rem",
                color: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={LocalImages.Logout}
                alt=""
                height="20px"
                width="20px"
                style={{ margin: "0rem 1rem 0rem 1.5rem" }}
              />
              Logout
              {/* <ExitToAppIcon sx={{ margin: "0rem .5rem 0rem 1.5rem" }} /> Logout */}
            </Typography>
          </Stack>
        }
        arrow
      >
        {children}
      </CustomToolTip>
    </>
  );
};

const RequestToolTip = ({ children }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <CustomRequestToolTip
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disableFocusListener
        placement="bottom-start"
        title={
          <Stack
            direction="column"
            sx={{
              width: "180px",
              "& p": {
                paddingX: "20px",
              },
              paddingY: "20px",
            }}
            spacing={2}
          >
            <Typography color="inherit" onClick={() => handleClose()}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.HEADER.REQUESTS}
              >
                My Requests
              </Link>
            </Typography>
            {/* <Typography color="inherit" onClick={() => handleClose()}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.SAVEDCOMPANY}
              >
                Saved Providers
              </Link>
            </Typography> */}
            <Typography color="inherit" onClick={() => handleClose()}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname?.landing}
              >
                Enquires
              </Link>
            </Typography>
          </Stack>
        }
        arrow
      >
        {children}
      </CustomRequestToolTip>
    </>
  );
};

const ProviderToolTip = ({ children }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <CustomRequestToolTip
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disableFocusListener
        placement="bottom-start"
        title={
          <Stack
            direction="column"
            sx={{
              width: "180px",
              "& p": {
                paddingX: "20px",
              },
              paddingY: "20px",
            }}
            spacing={2}
          >
            <Typography color="inherit" onClick={() => handleClose()}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.landing}
              >
                All Providers
              </Link>
            </Typography>
            <Typography color="inherit" onClick={() => handleClose()}>
              <Link
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                to={Pathname.SAVEDCOMPANY}
              >
                Saved Providers
              </Link>
            </Typography>
          </Stack>
        }
        arrow
      >
        {children}
      </CustomRequestToolTip>
    </>
  );
};

export default Header;
